export const MAXIMUM_IMAGE_SIZE = 2 * 1024 * 1024; //less than 2MB in bytes

export const NOTIFICATION_MESSAGES = {
  'contact-form-success': "Thanks for your message, we'll get back to you soon!",
  'project-form-success': "Thanks for submitting your project! We will review it before publishing it on the website."
}

export const PARTNER_REGION_OPTIONS = [
  "Africa",
  "East Africa",
  "West Africa",
  "North Africa",
  "Southern Africa",
  "Algeria",
  "Angola",
  "Benin",
  "Botswana",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cameroon",
  "Central African Republic (CAR)",
  "Chad",
  "Comoros",
  "Democratic Republic of the Congo",
  "Republic of the Congo",
  "Cote d'Ivoire",
  "Djibouti",
  "Egypt",
  "Equatorial Guinea",
  "Eritrea",
  "Eswatini (formerly Swaziland)",
  "Ethiopia",
  "Gabon",
  "Gambia",
  "Ghana",
  "Guinea",
  "Guinea-Bissau",
  "Kenya",
  "Lesotho",
  "Liberia",
  "Libya",
  "Madagascar",
  "Malawi",
  "Mali",
  "Mauritania",
  "Mauritius",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Niger",
  "Nigeria",
  "Rwanda",
  "Sao Tome and Principe",
  "Senegal",
  "Seychelles",
  "Sierra Leone",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Sudan",
  "Tanzania",
  "Togo",
  "Tunisia",
  "Uganda",
  "Zambia",
  "Zimbabwe",
]

export const FORM_QUESTIONS = [
  { key: "status", label: "Status" },
  { key: "firstname", label: "First name" },
  { key: "lastname", label: "Last name" },
  { key: "email", label: "Email" },
  { key: "website", label: "Website" },
  { key: "phone", label: "Phone number" },
  { key: "whatsapp", label: "May be contacted on Whatsapp" },
  { key: "company", label: "Organization" },
  { key: "position", label: "Job title" },
  { key: "referrer", label: "How did you find out about this event?" },
  { key: "location", label: "Where are you currently based?" },
  { key: "role", label: "Are you applying as an Adopter or an Ecosystem Partner?" },
  { key: "nationality", label: "What is your nationality?", role: "Adopter" },
  { key: "bmodel", label: "Which model are you interested in?", role: "Adopter" },
  { key: "orgtype", label: "How are you applying?", role: "Adopter" },
  { key: "interest", label: "Why are you interested in adopting or replicating this business model?", role: "Adopter" },
  { key: "profile", label: "What makes you a good fit for replicating the model? How well do you respond to the adopter criteria shown in the adopter profile?", role: "Adopter" },
  { key: "financing", label: "How would you potentially finance/co-finance the replication of the model?", role: "Adopter" },
  { key: "partner_bmodel", label: "Which model(s) are you interested in?", role: "Partner" },
  { key: "support_region", label: "Which countries or regions does your organization support?", role: "Partner" },
  { key: "partner_sector", label: "In which sectors do you work?", role: "Partner" },
  { key: "support_type", label: "What kind of support can you offer originators?", role: "Partner" },
  { key: "additonal_support", label: "Please provide more details on how you can support originators.", role: "Partner" },
  { key: "additional", label: "Any additional info?" },
]

export const ORIGINATOR_OPTIONS = [
  "Ekutir",
  "LifeBank",
  "ONergy",
  "Ignitia",
]

export const API_ENDPOINT = "thawing-sierra-34448.herokuapp.com"
